import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import BiotechIcon from "@mui/icons-material/Biotech";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";
// import HouseIcon from "@mui/icons-material/House";
// import PeopleIcon from "@mui/icons-material/People";
// import DashboardIcon from "@mui/icons-material/Dashboard";
// import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
// import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button, Tooltip } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
// import EventAvailableIcon from "@mui/icons-material/EventAvailable";

import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ViewListIcon from "@mui/icons-material/ViewList";
import BadgeIcon from "@mui/icons-material/Badge";
import SourceIcon from "@mui/icons-material/Source";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import RvHookupIcon from "@mui/icons-material/RvHookup";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import TimelineIcon from "@mui/icons-material/Timeline";

import GroupsIcon from "@mui/icons-material/Groups";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import profile_funnel from "../Media/profile_funnel.png";
import PlaceIcon from "@mui/icons-material/Place";
import RedeemIcon from "@mui/icons-material/Redeem";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import FunctionsIcon from "@mui/icons-material/Functions";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SocialDistanceIcon from "@mui/icons-material/SocialDistance";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import SpeedIcon from "@mui/icons-material/Speed";
import corporate_icon from "../Media/corporate.png";
import corporate_selected from "../Media/corporate_selected.png";

import QueryStatsIcon from "@mui/icons-material/QueryStats";
import atlas_icon from "../Media/logo.png";
import hub_icon from "../Media/hub_logo.png";
import client_icon from "../Media/client.png";
import oh_icon from "../Media/corporate_oh.png";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import leads from "../Media/Leads.png"
import invoice_dashboard_img from "../Media/dashboard.png"
import corporate_oh_icon from "../Media/corporate_oh_dash.png";
import logo_img from "../Media/equinox_logo.png"
import fnb_testing from "../Media/fnb_testing.png"
import fnb_testing_selected from "../Media/fnb_testing_selected.png"
import analystics_icon from "../Media/data-analytics.png"
import assigned_leads from "../Media/assigned_leads.png"
import relevant_marketing from "../Media/relevant_marketing.png"
import sample_type from "../Media/sample_type.png"
import menu_icon from "../Media/menu.png"
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ header_name }) {
  const storage = window.sessionStorage;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [change1, setChange1] = useState(false);
  const [change2, setChange2] = useState(false);
  const [change3, setChange3] = useState(false);
  const [change4, setChange4] = useState(false);
  const [change5, setChange5] = useState(false);
  const [change6, setChange6] = useState(false);
  const [change7, setChange7] = useState(false);
  const [change8, setChange8] = useState(false);
  const [change9, setChange9] = useState(false);
  const [change10, setChange10] = useState(false);
  const [change11, setChange11] = useState(false);
  const [change12, setChange12] = useState(false);
  const [change13, setChange13] = useState(false);
  const [change14, setChange14] = useState(false);
  const [change15, setChange15] = useState(false);
  const [change16, setChange16] = useState(false);
  const [change17, setChange17] = useState(false);
  const [change18, setChange18] = useState(false);
  const [change19, setChange19] = useState(false);
  const [change20, setChange20] = useState(false);
  const [change21, setChange21] = useState(false);
  const [change22, setChange22] = useState(false);

  const [change_marketing, setChange_Marketing] = useState(false);
  const [change_quality, setChange_Quality] = useState(false);
  const [change_corporate, setChange_Corporate] = useState(false);
  const [change_fnb, setChange_Fnb] = useState(false);
  const [change_hub, setChange_Hub] = useState(false);
  const [change_hub_fnb, setChange_Hub_Fnb] = useState(false);
  const [change_atlas, setChange_Atlas] = useState(false);
  const [change_invoice, setChange_Invoice] = useState(false);
  const [change_invoice_fnb, setChange_Invoice_Fnb] = useState(false);
  const [change_monthly, setChange_Monthly] = useState(false);
  const [change_monthly_fnb, setChange_Monthly_Fnb] = useState(false);

  let img = storage.getItem("profile_img");
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");
  let DEPT_ID = storage.getItem("DEPT_ID");
  let department = storage.getItem("DEPT");

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show_marketing, setShow_Marketing] = useState(false);
  const [show_quality, setShow_Quality] = useState(false);
  const [show_corporate, setShow_Corporate] = useState(false);
  const [show_fnb, setShow_Fnb] = useState(false);
  const [show_hub, setShow_Hub] = useState(false);
  const [show_hub_fnb, setShow_Hub_Fnb] = useState(false);
  const [show_atlas, setShow_Atlas] = useState(false);
  const [show_invoice, setShow_Invoice] = useState(false);
  const [show_invoice_fnb, setShow_Invoice_Fnb] = useState(false);
  const [show_monthly,setShow_Monthly] = useState(false)
  const [show_monthly_fnb,setShow_Monthly_Fnb] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setShow(false);
    setShow1(false);
    setShow2(false);
    setShow3(false);
    setShow4(false);
    setShow5(false);
    setShow6(false);
    setShow_Marketing(false);
    setShow_Quality(false);
    setShow_Corporate(false);
    setShow_Atlas(false);
    setShow_Hub(false);
    setShow_Hub_Fnb(false);
    setShow_Invoice(false);
    setShow_Invoice_Fnb(false);
    setShow_Monthly(false);
    setShow_Monthly_Fnb(false);
    setShow_Fnb(false);
  };

  const handleLogout = () => {
    storage.setItem("USER_GUID", "");
    storage.setItem("profile_img", "");
    storage.setItem("Role_id", "");
    storage.setItem("DEPT_ID", "");
    storage.setItem("admin_param", "");
    // storage.setItem("DEPT", "");
    window.location.reload();
  };

  const handlePath = (e, path,param) => {
    if (e.ctrlKey) {
      if(param == "fnb"){
        storage.setItem("admin_param","1")
      }else if(param == "corporate"){
        storage.setItem("admin_param","2")
      }else{
        // storage.setItem("admin_param","")
        let x=1;
      }
      window.open(path, "_blank");
    } else {
      if(param == "fnb"){
        storage.setItem("admin_param","1")
      }else if(param == "corporate"){
        storage.setItem("admin_param","2")
      }else{
        // storage.setItem("admin_param","")
        let x=1;
      }
      navigate(path);

    }
  };


  const candidate_handle = (e) => {
    storage.setItem("load_count", 0);
    // navigate("/Salesperson_Wise");
    handlePath(e, "/leads_added_by_sales");
  };

  React.useEffect(() => {
    if (
      USER_GUID == "" ||
      Role_id == "" ||
      DEPT_ID == "" ||
      USER_GUID == null ||
      Role_id == null ||
      DEPT_ID == null
    ) {
      navigate("/");
    }
  }, [storage]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
          sx={{
            backgroundColor: "#007cc3",
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex" }}>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
                color: "white",
              }}
            >
              <MenuIcon />
            </IconButton> */}
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{ marginTop: "1.8%" }}
            >
              {header_name}
            </Typography>
          </Box>
          <Box
            sx={{
              // marginLeft: "70%",
              display: "flex",
              marginTop: "5px",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <img
              src={img}
              alt=""
              style={{
                height: "40px",
                width: "40px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
            <p style={{ marginTop: "15px" }}>{/* {f_name} {l_name} */}</p>
            <Tooltip title="Sign Out" placement="top">
              <div
                style={{
                  marginLeft: "0px",
                  color: "#F0EEED",
                  backgroundColor: "#007cc3",
                  height: "30px",
                  width: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
                onClick={handleLogout}
              >
                <PowerSettingsNewIcon sx={{ height: "20px" }} />
              </div>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        onMouseLeave={handleDrawerClose}
        onMouseOver={handleDrawerOpen}
      >
        <DrawerHeader sx={{ backgroundColor: "white" }}>
          {/* <IconButton
            //  onClick={handleDrawerClose}
            sx={{ color: "#007cc3" }}
            id={styles.headerIcon}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon id={styles.headerIcon} />
            ) : (
              <ChevronLeftIcon id={styles.headerIcon} />
            )}
          </IconButton> */}
          <img style={{height:"40px",margin:"auto"}} src={logo_img} alt="" />
        </DrawerHeader>
        <Divider />
        <List>
          {department == "Tech" ||
          department == "Admin" ||
          department == "Corporate Sales" ||
          department == "FnB Sales" ||
          department == "Labs" ||
          department == "Management" ||
          department == "Marketing" ? (
            <ListItem
              disablePadding
              // sx={{ display: "block",backgroundColor:show?"#444a4f":"#89BBE0"}}
              sx={{
                display: "block",
                backgroundColor: show ? "white" : "#007cc3",
              }}
              // onClick={() => navigate("/Category_Leadsource_wise")}
              onClick={() => setShow(!show)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <LeaderboardIcon
                    sx={{ color: show ? "#007cc3" : "white", fontSize: "23px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={show ? "S6 ▲" : "S6 ▼"}
                  sx={{
                    opacity: open ? 1 : 0,
                    marginTop: "7px",
                    color: show
                      ? "#007cc3"
                      : !show && change3
                      ? "white"
                      : "white",
                  }}
                  onMouseEnter={() => setChange3(true)}
                  onMouseLeave={() => setChange3(false)}
                />
              </ListItemButton>
            </ListItem>
          ) : (
            <></>
          )}
          {show ? (
            <>
              {department == "Tech" ||
              department == "Admin" ||
              department == "Corporate Sales" ||
              department == "FnB Sales" ||
              department == "Management" ||
              department == "Marketing" ? (
                <>
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    // onClick={() => navigate("/Category_Leadsource_wise")}
                    onClick={(e) => handlePath(e, "/Category_Leadsource_wise")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <SourceIcon sx={{ color: "white", fontSize: "23px" }} />
                        {/* <PersonSearchIcon sx={{ color: "white", fontSize: "23px" }} /> */}
                      </ListItemIcon>
                      <ListItemText
                        primary={"Leadsource"}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                        }}
                        onMouseEnter={() => setChange1(true)}
                        onMouseLeave={() => setChange1(false)}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    // onClick={() => navigate("/Category_Leadsource_wise")}
                    onClick={(e) => handlePath(e, "/leadsource_vs_revenue")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {/* <img src={leadsource_img} style={{height:"20px"}}/> */}
                        <QueryStatsIcon
                          sx={{
                            color: "white",
                            fontSize: "23px",
                            marginTop: "-10px",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                        }}
                        // onMouseEnter={() => setChange5(true)}
                        // onMouseLeave={() => setChange5(false)}
                      >
                        Leadsource{" "}
                        <p style={{ marginBottom: "0rem" }}>vs Revenue</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  {department == "Tech" ||
                  department == "Admin" ||
                  department == "Corporate Sales" ||
                  department == "FnB Sales" ||
                  department == "Labs" ||
                  department == "Management" ? (
                    <ListItem
                      disablePadding
                      sx={{ display: "block", marginLeft: "20px" }}
                      onClick={candidate_handle}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          pt: 1,
                          pb: 0,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <AssessmentIcon
                            sx={{
                              color: "white",
                              fontSize: "23px",
                              marginTop: "-10px",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            color: "white",
                          }}
                          onMouseEnter={() => setChange5(true)}
                          onMouseLeave={() => setChange5(false)}
                        >
                          Leads Added{" "}
                          <p style={{ marginBottom: "0rem" }}>By Sales</p>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ) : (
                    <></>
                  )}

                  {/* <ListItem
                disablePadding
                sx={{ display: "block", marginLeft: "20px" }}
                onClick={(e)=>handlePath(e,"/hub_wise_data")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <InventoryIcon
                      sx={{ color: "#444a4f", fontSize: "23px" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Hub Wise Data"}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: change7 ? "#444a4f" : "black",
                    }}
                    onMouseEnter={() => setChange7(true)}
                    onMouseLeave={() => setChange7(false)}
                  />
                </ListItemButton>
              </ListItem> */}

                  {department == "Tech" ||
                  department == "Admin" ||
                  department == "Corporate Sales" ||
                  department == "FnB Sales" ||
                  department == "Labs" ||
                  department == "Management" ? (
                    <ListItem
                      disablePadding
                      sx={{ display: "block", marginLeft: "20px" }}
                      // onClick={() => navigate("/geo_wise_data")}
                      onClick={(e) => handlePath(e, "/revenue_citywise")}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {/* <img src={revenue_city} style={{height:"20px"}}/> */}
                          <TravelExploreIcon
                            sx={{ color: "white", fontSize: "23px" }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          // primary={"Revenue-Citywise"}
                          sx={{
                            opacity: open ? 1 : 0,
                            color: "white",
                          }}
                          onMouseEnter={() => setChange8(true)}
                          onMouseLeave={() => setChange8(false)}
                        >
                          <span>Revenue-Citywise</span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ) : (
                    <></>
                  )}

                  {department == "Tech" ||
                  department == "Admin" ||
                  department == "Corporate Sales" ||
                  department == "FnB Sales" ||
                  department == "Labs" ||
                  department == "Management" ? (
                    <ListItem
                      disablePadding
                      sx={{ display: "block", marginLeft: "20px" }}
                      // onClick={() => navigate("/geo_wise_data")}
                      onClick={(e) => handlePath(e, "/bde_dashboard")}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {/* <img src={bde_img} style={{height:"20px"}} /> */}

                          <DashboardIcon
                            sx={{ color: "white", fontSize: "23px" }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          // primary={"Revenue-Citywise"}
                          sx={{
                            opacity: open ? 1 : 0,
                            color: "white",
                          }}
                          onMouseEnter={() => setChange8(true)}
                          onMouseLeave={() => setChange8(false)}
                        >
                          <span>BDE Dashboard</span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {department == "Tech" ||
              department == "Admin" ||
              department == "Management" ||
              department == "Labs" ? (
                <>
                  {" "}
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    // onClick={() => navigate("/lab_wise_data")}
                    onClick={(e) => handlePath(e, "/lab_wise_data")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {/* <TroubleshootIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        /> */}
                        <SummarizeIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={"Report Insights"}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                        }}
                        onMouseEnter={() => setChange9(true)}
                        onMouseLeave={() => setChange9(false)}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    // onClick={() => navigate("/hub_wise_lab_data")}
                    onClick={(e) => handlePath(e, "/hub_wise_report_released")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        py: 0,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {/* <img src={hub_img} style={{height:"20px"}} /> */}

                        <AnalyticsIcon
                          sx={{
                            color: "white",
                            fontSize: "23px",
                            marginTop: "-10px",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        // primary={"Hub Wise Report"}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                        }}
                        onMouseEnter={() => setChange10(true)}
                        onMouseLeave={() => setChange10(false)}
                      >
                        Hub Wise Report
                        <p style={{ marginBottom: "0rem" }}>Released</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) => handlePath(e, "/testing_location")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        py: 0,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {/* <img src={testing_img} style={{height:"20px"}} /> */}
                        <PlaceIcon
                          sx={{
                            color: "white",
                            fontSize: "23px",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={"Testing Location"}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </>
              ) : (
                <></>
              )}

              {department == "Tech" ||
              department == "Admin" ||
              department == "Corporate Sales" ||
              department == "FnB Sales" ||
              department == "Management" ? (
                <>
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    // onClick={() => navigate("/hub_wise_lab_data")}
                    onClick={(e) => handlePath(e, "/client_type_revenue")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        py: 0,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {/* <img src={client_img} alt="" style={{height:"20px"}} /> */}
                        <RedeemIcon
                          sx={{
                            color: "white",
                            fontSize: "23px",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={"Client Revenue"}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                        }}
                        // onMouseEnter={() => setChange10(true)}
                        // onMouseLeave={() => setChange10(false)}
                      />
                      {/* Hub Wise Report
                        <p style={{ marginBottom: "0rem" }}>Released</p>
                      </ListItemText> */}
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) =>
                      handlePath(e, "/client_type_sample_revenue")
                    }
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        py: 0,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {/* <img src={client_type} alt="" style={{height:"25px"}} /> */}
                        <FolderSharedIcon
                          sx={{
                            color: "white",
                            fontSize: "23px",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,

                          color: "white",
                        }}
                      >
                        Client Type Sample{" "}
                        <p style={{ marginBottom: "0rem" }}>Type Revenue</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) =>
                      handlePath(e, "/salesperson_client_type_sample_type")
                    }
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        py: 0,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {/* <img src={salesperson} style={{height:"25px"}} /> */}
                        <AccountBoxIcon
                          sx={{
                            color: "white",
                            fontSize: "23px",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,

                          color: "white",
                        }}
                      >
                        Salesperson Client{" "}
                        <p style={{ marginBottom: "0rem" }}>Sample Type</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {department == "Tech" ||
          department == "Admin" ||
          department == "Management" ||
          department == "Corporate Sales" ||
          department == "FnB Sales" ? (
            <>
              {" "}
              <ListItem
                disablePadding
                sx={{ display: "block", p: 0 }}
                // onClick={() => navigate("/order_history")}
                onClick={(e) => handlePath(e, "/new_vs_repeated_business")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    // py:1
                    pt: 1,
                    pb: 0,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {/* <LibraryAddIcon sx={{ color: "#007cc3", fontSize: "27px" }} /> */}
                    {/* <ViewListIcon sx={{ color: "#444a4f", fontSize: "23px",marginTop:"-10px" }} /> */}
                    <ViewListIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    // primary={"New vs Repeated"}
                    sx={{
                      opacity: open ? 1 : 0,
                      // color: change4 ? "#444a4f" : "black",
                      color: "white",
                    }}
                    onMouseEnter={() => setChange4(true)}
                    onMouseLeave={() => setChange4(false)}
                  >
                    New vs Repeated -{" "}
                    <p style={{ marginBottom: "0rem" }}>Business</p>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                // onClick={() =>
                //   navigate("/Individual_sales_user_performance_dashboard")
                // }
                onClick={(e) =>
                  handlePath(e, "/Individual_sales_performance_leads")
                }
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    pt: 1,
                    pb: 0,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {/* <BadgeIcon sx={{ color: "#444a4f", fontSize: "23px",marginTop:"-10px" }} /> */}
                    <BadgeIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    // primary={"Individual Sales"}
                    sx={{
                      opacity: open ? 1 : 0,
                      // color: change6 ? "#444a4f" : "black",
                      color: "white",
                    }}
                    onMouseEnter={() => setChange6(true)}
                    onMouseLeave={() => setChange6(false)}
                  >
                    Individual Sales
                    <p style={{ marginBottom: "0rem" }}>Performance Leads</p>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                // onClick={() => navigate("/main")}
                onClick={(e) => handlePath(e, "/samples_tested_vs_revenue")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {/* <CorporateFareIcon
                  sx={{ color: "#444a4f", fontSize: "23px" }}
                /> */}
                    <CorporateFareIcon sx={{ color: "white" }} />
                    {/* <SpeedIcon /> */}
                  </ListItemIcon>

                  <ListItemText
                    sx={{
                      opacity: open ? 1 : 0,
                      // color: change2 ? "#444a4f" : "black",
                      color: "white",
                    }}
                    onMouseEnter={() => setChange2(true)}
                    onMouseLeave={() => setChange2(false)}
                  >
                    Samples
                    <p style={{ marginBottom: "0rem" }}>Tested vs Revenue</p>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </>
          ) : (
            <></>
          )}
          {/* Operation section */}
          {department == "Tech" ||
          department == "Admin" ||
          department == "Management" ? (
            <ListItem
              disablePadding
              sx={{
                display: "block",
                backgroundColor: show1 ? "white" : "#007cc3",
              }}
              // onClick={() => navigate("/Category_Leadsource_wise")}
              onClick={() => setShow1(!show1)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {/* <LeaderboardIcon sx={{ color: "#444a4f", fontSize: "27px" }} /> */}
                  <AccountTreeIcon
                    sx={{
                      color: show1 ? "#007cc3" : "white",
                      fontSize: "23px",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  // primary={"Operation"}
                  primary={show1 ? "Operation ▲" : "Operation ▼"}
                  sx={{
                    opacity: open ? 1 : 0,
                    color: show1
                      ? "#007cc3"
                      : !show1 && change11
                      ? "white"
                      : "white",
                  }}
                  onMouseEnter={() => setChange11(true)}
                  onMouseLeave={() => setChange11(false)}
                />
              </ListItemButton>
            </ListItem>
          ) : (
            <></>
          )}

          {show1 ? (
            <>
              {/* Operation Report */}
              {department == "Tech" ||
              department == "Admin" ||
              department == "Management" ? (
                <ListItem
                  disablePadding
                  sx={{ display: "block", marginLeft: "20px" }}
                  // onClick={() => navigate("/operation_report")}
                  onClick={(e) => handlePath(e, "/logistics_report")}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {/* <CorporateFareIcon sx={{ color: "#444a4f", fontSize: "27px" }} /> */}
                      <RvHookupIcon sx={{ color: "white" }} />
                      {/* <SpeedIcon /> */}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Logistics"}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "white",
                      }}
                      onMouseEnter={() => setChange12(true)}
                      onMouseLeave={() => setChange12(false)}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                <></>
              )}
              {/* Registrations Report */}
              {department == "Tech" ||
              department == "Admin" ||
              department == "Management" ? (
                <ListItem
                  disablePadding
                  sx={{ display: "block", marginLeft: "20px" }}
                  // onClick={() => navigate("/registration_report")}
                  onClick={(e) => handlePath(e, "/registration_report")}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <AppRegistrationIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Registration"}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "white",
                      }}
                      onMouseEnter={() => setChange13(true)}
                      onMouseLeave={() => setChange13(false)}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {/* NPS Dashboard */}
          {department == "Tech" ||
          department == "Admin" ||
          department == "Management" ||
          department == "Marketing" ? (
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              // onClick={() => navigate("/nps_dashboard")}
              onClick={(e) => handlePath(e, "/nps")}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SignalCellularAltIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary={"NPS"}
                  sx={{
                    opacity: open ? 1 : 0,
                    color: "white",
                  }}
                  onMouseEnter={() => setChange19(true)}
                  onMouseLeave={() => setChange19(false)}
                />
              </ListItemButton>
            </ListItem>
          ) : (
            <></>
          )}

          {/* Accounts section */}
          {department == "Tech" ||
          department == "Admin" ||
          department == "Management" ||
          department == "Accounts" ? (
            <ListItem
              disablePadding
              sx={{
                display: "block",
                backgroundColor: show2 ? "white" : "#007cc3",
              }}
              // onClick={() => navigate("/Category_Leadsource_wise")}
              onClick={() => setShow2(!show2)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {/* <img src={account_icon} alt="" style={{ width: "23px" }} /> */}
                  <LocalMallIcon
                    sx={{ width: "23px", color: show2 ? "#007cc3" : "white" }}
                  />
                </ListItemIcon>
                <ListItemText
                  // primary={"Accounts"}
                  primary={show2 ? "Accounts ▲" : "Accounts ▼"}
                  sx={{
                    opacity: open ? 1 : 0,
                    color: show2
                      ? "#007cc3"
                      : !show2 && change14
                      ? "white"
                      : "white",
                  }}
                  onMouseEnter={() => setChange14(true)}
                  onMouseLeave={() => setChange14(false)}
                />
              </ListItemButton>
            </ListItem>
          ) : (
            <></>
          )}

          {show2 ? (
            // payment Report
            <>
              {department == "Tech" ||
              department == "Admin" ||
              department == "Management" ||
              department == "Accounts" ? (
                <ListItem
                  disablePadding
                  sx={{ display: "block", marginLeft: "20px" }}
                  onClick={(e) => handlePath(e, "/payment_report")}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <AccountBalanceWalletIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Payment Report"}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "white",
                      }}
                      onMouseEnter={() => setChange15(true)}
                      onMouseLeave={() => setChange15(false)}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                <></>
              )}

              {department == "Tech" ||
              department == "Admin" ||
              department == "Management" ||
              department == "Accounts" ? (
                <ListItem
                  disablePadding
                  sx={{ display: "block", marginLeft: "17px" }}
                  onClick={(e) => handlePath(e, "/transaction_report")}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <ReceiptLongIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Transaction Report"}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "white",
                        marginLeft: "-5px",
                      }}
                      onMouseEnter={() => setChange16(true)}
                      onMouseLeave={() => setChange16(false)}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {department == "Tech" ||
          department == "Admin" ||
          department == "Corporate Sales" ||
          department == "FnB Sales" ? (
            <>
              {" "}
              <ListItem
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor: show6 ? "white" : "#007cc3",
                }}
                onClick={() => setShow6(!show6)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <TimelineIcon
                      sx={{ width: "23px", color: show6 ? "#007cc3" : "white" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={show6 ? "Sales Analysis ▲" : "Sales Analysis ▼"}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: show6
                        ? "#007cc3"
                        : !show6 && change17
                        ? "white"
                        : "white",
                    }}
                    onMouseEnter={() => setChange17(true)}
                    onMouseLeave={() => setChange17(false)}
                  />
                </ListItemButton>
              </ListItem>
              {show6 ? (
                <>
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) => handlePath(e, "/sales_analysis")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <CurrencyRupeeIcon sx={{ color: "white" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={"Overall Revenue"}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                        }}
                        onMouseEnter={() => setChange18(true)}
                        onMouseLeave={() => setChange18(false)}
                      />
                    </ListItemButton>
                  </ListItem>
                  {/* <ListItem
                disablePadding
                sx={{ display: "block", marginLeft: "20px" }}
                onClick={(e) => handlePath(e, "/testing_service")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <BiotechIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Testing Service"}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: "white",
                    }}
                    onMouseEnter={() => setChange18(true)}
                    onMouseLeave={() => setChange18(false)}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block", marginLeft: "20px" }}
                onClick={(e) => handlePath(e, "/non_testing_service")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <LabelIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Non Testing Service"}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: "white",
                      marginLeft:"-5px"
                    }}
                    onMouseEnter={() => setChange18(true)}
                    onMouseLeave={() => setChange18(false)}
                  />
                </ListItemButton>
              </ListItem> */}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {department == "Tech" ||
          department == "Admin" ||
          department == "Management" ||
          department == "Office of CEO" ? (
            <ListItem
              disablePadding
              sx={{
                display: "block",
                backgroundColor: show5 ? "white" : "#007cc3",
              }}
              onClick={() => setShow5(!show5)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PersonSearchIcon
                    sx={{
                      fontSize: "23px",
                      color: show5 ? "#007cc3" : "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={show5 ? "HR ▲" : "HR ▼"}
                  sx={{
                    opacity: open ? 1 : 0,
                    color: show5
                      ? "#007cc3"
                      : !show5 && change20
                      ? "white"
                      : "white",
                  }}
                  onMouseEnter={() => setChange20(true)}
                  onMouseLeave={() => setChange20(false)}
                />
              </ListItemButton>
            </ListItem>
          ) : (
            <></>
          )}

          {show5 ? (
            <>
              <ListItem
                disablePadding
                sx={{ display: "block", marginLeft: "20px" }}
                onClick={(e) => handlePath(e, "/hr_team_s6")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <GroupsIcon style={{ fontSize: "23px", color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Team S6"}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: "white",
                    }}
                    onMouseEnter={() => setChange22(true)}
                    onMouseLeave={() => setChange22(false)}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block", marginLeft: "20px" }}
                onClick={(e) => handlePath(e, "/profile_metrics")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={profile_funnel}
                      alt=""
                      style={{ width: "23px" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Profile Metrics"}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: "white",
                    }}
                    onMouseEnter={() => setChange21(true)}
                    onMouseLeave={() => setChange21(false)}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block", marginLeft: "20px" }}
                onClick={(e) => handlePath(e, "/offer_sent_accept_dashboard")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <BusinessCenterIcon
                      style={{ fontSize: "23px", color: "white" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      opacity: open ? 1 : 0,
                      // color: change2 ? "#444a4f" : "black",
                      color: "white",
                      marginLeft: "-7px",
                    }}
                    // onMouseEnter={() => setChange2(true)}
                    // onMouseLeave={() => setChange2(false)}
                  >
                    Offer Sent &
                    <p style={{ marginBottom: "0rem" }}>Accepted Dashboard</p>
                  </ListItemText>
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block", marginLeft: "20px" }}
                onClick={(e) => handlePath(e, "/average_ttf_dashboard")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <FunctionsIcon
                      style={{ fontSize: "23px", color: "white" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      opacity: open ? 1 : 0,

                      color: "white",
                    }}
                  >
                    <p style={{ marginBottom: "0rem" }}>Average TTF</p>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </>
          ) : (
            <></>
          )}

          {department == "Tech" ||
          department == "Admin" ||
          department == "Management" ||
          department == "Marketing" ? (
            <ListItem
              disablePadding
              // sx={{ display: "block",backgroundColor:show?"#444a4f":"#89BBE0"}}
              sx={{
                display: "block",
                backgroundColor: show_marketing ? "white" : "#007cc3",
              }}
              // onClick={() => navigate("/Category_Leadsource_wise")}
              onClick={() => setShow_Marketing(!show_marketing)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SocialDistanceIcon
                    sx={{
                      color: show_marketing ? "#007cc3" : "white",
                      fontSize: "23px",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={show_marketing ? "Marketing ▲" : "Marketing ▼"}
                  sx={{
                    opacity: open ? 1 : 0,
                    marginTop: "7px",
                    color: show_marketing
                      ? "#007cc3"
                      : !show_marketing && change_marketing
                      ? "white"
                      : "white",
                  }}
                  onMouseEnter={() => setChange_Marketing(true)}
                  onMouseLeave={() => setChange_Marketing(false)}
                />
              </ListItemButton>
            </ListItem>
          ) : (
            <></>
          )}

          {show_marketing ? (
            <>
              {department == "Tech" ||
              department == "Admin" ||
              department == "Management" ||
              department == "Marketing" ? (
                <>
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    // onClick={() => navigate("/Category_Leadsource_wise")}
                    onClick={(e) => handlePath(e, "/marketing_leadsource")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <AddToPhotosIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        />
                      </ListItemIcon>
                      {/* <ListItemText
                        primary={"Marketing Leadsource"}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                        }}
                        onMouseEnter={() => setChange1(true)}
                        onMouseLeave={() => setChange1(false)}
                      /> */}
                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          // color: change2 ? "#444a4f" : "black",
                          color: "white",
                          marginLeft: "-7px",
                        }}
                        // onMouseEnter={() => setChange1(true)}
                        // onMouseLeave={() => setChange2(false)}
                      >
                        Marketing
                        <p style={{ marginBottom: "0rem" }}>Leadsource</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    // onClick={() => navigate("/Category_Leadsource_wise")}
                    onClick={(e) => handlePath(e, "/lead_funnel")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FilterAltIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={"Lead Funnel"}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                        }}
                        onMouseEnter={() => setChange1(true)}
                        onMouseLeave={() => setChange1(false)}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    // onClick={() => navigate("/Category_Leadsource_wise")}
                    onClick={(e) => handlePath(e, "/marketing_performance")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <PublishedWithChangesIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          // color: change2 ? "#444a4f" : "black",
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Marketing
                        <p style={{ marginBottom: "0rem" }}>Performance</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    // onClick={() => navigate("/Category_Leadsource_wise")}
                    onClick={(e) => handlePath(e, "/relevant_marketing_performance")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                 <img src={relevant_marketing} style={{height:"24px"}} alt="" />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          // color: change2 ? "#444a4f" : "black",
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Relevant Marketing
                        <p style={{ marginBottom: "0rem" }}>Performance</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

{/* 
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) => handlePath(e, "/assigned_marketing_performance")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                 <img src={assigned_leads} style={{height:"20px"}} alt="" />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Assigned Leads

                      </ListItemText>
                    </ListItemButton>
                  </ListItem> */}


                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    // onClick={() => navigate("/Category_Leadsource_wise")}
                    onClick={(e) => handlePath(e, "/category_leadsourcewise_analysis")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                    <img src={analystics_icon} style={{height:"20px"}} alt="" />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          // color: change2 ? "#444a4f" : "black",
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Category Analysis
                        {/* <p style={{ marginBottom: "0rem" }}>Performance</p> */}
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    // onClick={() => navigate("/Category_Leadsource_wise")}
                    onClick={(e) => handlePath(e, "/marketing_lead_assigned")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                    <img src={assigned_leads} style={{height:"20px"}} alt="" />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          // color: change2 ? "#444a4f" : "black",
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Marketing Leads 
                        <p style={{ marginBottom: "0rem" }}>Assigned</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {department == "Tech" ||
          department == "Admin" ||
          department == "Management" ||
          department == "Labs" ? (
            <>
              <ListItem
                disablePadding
                // sx={{ display: "block",backgroundColor:show?"#444a4f":"#89BBE0"}}
                sx={{
                  display: "block",
                  backgroundColor: show_quality ? "white" : "#007cc3",
                }}
                // onClick={() => navigate("/Category_Leadsource_wise")}
                onClick={() => setShow_Quality(!show_quality)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <BiotechIcon
                      sx={{
                        color: show_quality ? "#007cc3" : "white",
                        fontSize: "23px",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={show_quality ? "Quality ▲" : "Quality ▼"}
                    sx={{
                      opacity: open ? 1 : 0,
                      marginTop: "7px",
                      color: show_quality
                        ? "#007cc3"
                        : !show_quality && change_quality
                        ? "white"
                        : "white",
                    }}
                    onMouseEnter={() => setChange_Quality(true)}
                    onMouseLeave={() => setChange_Quality(false)}
                  />
                </ListItemButton>
              </ListItem>

              {show_quality ? (
                <>
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    // onClick={() => navigate("/Category_Leadsource_wise")}
                    onClick={(e) => handlePath(e, "/qc_utilization")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <SpeedIcon sx={{ color: "white", fontSize: "23px" }} />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        <p style={{ marginBottom: "0rem" }}>QC Utilization</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

             {/* Fnb Sales Section Starts */}

 {department == "Tech" ||
          department == "Admin" ||
          department == "Management" ||
          department == "FnB Sales" ? 
            <>   <ListItem
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor: show_fnb ? "white" : "#007cc3",
                }}
  
                onClick={() => setShow_Fnb(!show_fnb)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >

                    {show_fnb ? (
                      <img src={fnb_testing_selected} alt="" />
                    ) : (
                      <img src={fnb_testing} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={show_fnb ? "FnB Sales ▲" : "FnB Sales ▼"}
                    sx={{
                      opacity: open ? 1 : 0,
                      marginTop: "7px",
                      color: show_fnb
                        ? "#007cc3"
                        : !show_fnb && change_fnb
                        ? "white"
                        : "white",
                    }}
                    onMouseEnter={() => setChange_Fnb(true)}
                    onMouseLeave={() => setChange_Fnb(false)}
                  />
                </ListItemButton>
              </ListItem>

</>:<></>} 

{/* For live build */}

{show_fnb ? (
                <ListItem
                  disablePadding
                  sx={{
                    display: "block",
                    backgroundColor: show_monthly_fnb ? "white" : "#007cc3",
                    marginLeft: "20px",
                    marginTop:"10px"
                  }}
                  onClick={() => setShow_Monthly_Fnb(!show_monthly_fnb)}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <CalendarMonthIcon
                    sx={{ color: show_monthly_fnb ? "#007cc3" : "white", fontSize: "23px" }}
                  />
                     
                    </ListItemIcon>
                    <ListItemText
                      primary={show_monthly_fnb ? "Monthly Digest ▲" : "Monthly Digest ▼"}
                      sx={{
                        opacity: open ? 1 : 0,
                        marginTop: "7px",
                        color: show_monthly_fnb
                          ? "#007cc3"
                          : !show_monthly_fnb && change_monthly_fnb
                          ? "white"
                          : "white",
                      }}
                      onMouseEnter={() => setChange_Monthly_Fnb(true)}
                      onMouseLeave={() => setChange_Monthly_Fnb(false)}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                <></>
              )}

              {show_monthly_fnb ? (
                <ListItem
                  disablePadding

                  sx={{
                    display: "block",
                    backgroundColor: show_hub_fnb ? "white" : "#007cc3",
                    marginLeft: "20px",
                    marginTop:"10px"
                  }}

                  onClick={() => setShow_Hub_Fnb(!show_hub_fnb)}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
        
                      {show_hub_fnb ? (
                        <img style={{height:"22px"}} src={hub_icon} alt="" />
                      ) : (
                        <img style={{height:"22px"}} src={hub_icon} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={show_hub_fnb ? "HUB ▲" : "HUB ▼"}
                      sx={{
                        opacity: open ? 1 : 0,
                        marginTop: "7px",
                        color: show_hub_fnb
                          ? "#007cc3"
                          : !show_hub_fnb && change_hub_fnb
                          ? "white"
                          : "white",
                      }}
                      onMouseEnter={() => setChange_Hub_Fnb(true)}
                      onMouseLeave={() => setChange_Hub_Fnb(false)}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                <></>
              )}

              {/* For live build */}

{show_hub_fnb ? 
                <>
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}

                    onClick={(e) => handlePath(e, "/sales_leaderboard_fnb","fnb")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
         

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        <p style={{ marginBottom: "0rem" }}>Sales Leaderboard</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}

                    onClick={(e) => handlePath(e, "/salesperson_wise_invoice_fnb","fnb")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
        

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Salesperson wise
                        <p style={{ marginBottom: "0rem" }}>Invoice</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}
      
                    onClick={(e) => handlePath(e, "/sample_business_report_fnb","fnb")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
           

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Sample Business
                        <p style={{ marginBottom: "0rem" }}>Report</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  </>:<></>}


{show_fnb ?(<>

{/* For live build */}

                <ListItem
                  disablePadding

                  sx={{
                    display: "block",
                    backgroundColor: show_invoice_fnb ? "white" : "#007cc3",
                    marginLeft: "20px",
                    marginTop:"10px"
                  }}
 
                  onClick={() => setShow_Invoice_Fnb(!show_invoice_fnb)}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
             <FileCopyIcon  sx={{ color: show_invoice_fnb?"#007cc3":"white", fontSize: "23px" }} />
                    </ListItemIcon>

                   {show_invoice_fnb ? <ListItemText
                              sx={{
                                opacity: open ? 1 : 0,
                                marginTop: "7px",
                                color: show_invoice_fnb
                                  ? "#007cc3"
                                  : !show_invoice_fnb && change_invoice_fnb
                                  ? "white"
                                  : "white",
                              }}
                              onMouseEnter={() => setChange_Invoice_Fnb(true)}
                              onMouseLeave={() => setChange_Invoice_Fnb(false)}
                      >
                        Invoice Creation 
                        <p style={{ marginBottom: "0rem" }}>Dashboard ▲</p>
                      </ListItemText>: <ListItemText
                              sx={{
                                opacity: open ? 1 : 0,
                                marginTop: "7px",
                                color: show_invoice_fnb
                                  ? "#007cc3"
                                  : !show_invoice_fnb && change_invoice_fnb
                                  ? "white"
                                  : "white",
                              }}
                              onMouseEnter={() => setChange_Invoice_Fnb(true)}
                              onMouseLeave={() => setChange_Invoice_Fnb(false)}
                      >
                        Invoice Creation 
                        <p style={{ marginBottom: "0rem" }}>Dashboard ▼</p>
                      </ListItemText>}
                  </ListItemButton>
                </ListItem>

                { show_invoice_fnb? <> <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}
                    onClick={(e) => handlePath(e, "/invoice_daily_fnb","fnb")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
               

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Invoice Daily
                        <p style={{ marginBottom: "0rem" }}>Report</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}
                    onClick={(e) => handlePath(e, "/invoice_monthly_fnb","fnb")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
          

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Invoice Monthly
                        <p style={{ marginBottom: "0rem" }}>Report</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}
                    onClick={(e) => handlePath(e, "/invoice_monthly_data_fnb","fnb")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >


                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Invoice Monthly
                        <p style={{ marginBottom: "0rem" }}>Data</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem></>:<></>}

{/* For live build */}

                  <ListItem
                  disablePadding
                  sx={{ display: "block", marginLeft: "20px" }}
                  onClick={(e) => handlePath(e, "/invoice_dashboard_fnb","fnb")}
                  >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                  <img src={invoice_dashboard_img} alt="" />
                    </ListItemIcon>

                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "white",
                        marginLeft: "-7px",
                      }}
                    >
                  
                      <p style={{ marginBottom: "0rem" }}> Invoice Dashboard</p>
                    </ListItemText>
                  </ListItemButton>
                  </ListItem>

                <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) => handlePath(e, "/interested_lead_report_fnb","fnb")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                     <img src={leads} alt="" />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Interested Lead
                        <p style={{ marginBottom: "0rem" }}>Reports</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) => handlePath(e, "/fnb_client_oh_dashboard","fnb")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                     <img src={client_icon} alt="" />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Corporate Client
                        <p style={{ marginBottom: "0rem" }}>OH Dashboard</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>


                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) => handlePath(e, "/fnb_order_details","fnb")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                     <img src={menu_icon} alt="" />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        <p style={{ marginBottom: "0rem" }}>Order Details</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                   <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) => handlePath(e, "/sample_type_report_bde_fnb","fnb")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                     <img src={sample_type} alt="" />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Sample Type
                        <p style={{ marginBottom: "0rem" }}>Report (BDE)</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem> 
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) => handlePath(e, "/new_customer_fnb","fnb")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                     <ContactEmergencyIcon sx={{color:"white"}} />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        New Customer
                        <p style={{ marginBottom: "0rem" }}>Details</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

</>
              ) : (
                <></>
              )}

 



              {/* Corporate Section Starts */}

          {department == "Tech" ||
          department == "Admin" ||
          department == "Management" ||
          department == "Corporate Sales" ? (
            <>
              <ListItem
                disablePadding
                // sx={{ display: "block",backgroundColor:show?"#444a4f":"#89BBE0"}}
                sx={{
                  display: "block",
                  backgroundColor: show_corporate ? "white" : "#007cc3",
                }}
                // onClick={() => navigate("/Category_Leadsource_wise")}
                onClick={() => setShow_Corporate(!show_corporate)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {/* <BiotechIcon
                    sx={{ color: show_corporate ? "#007cc3" : "white", fontSize: "23px" }}
                  /> */}
                    {show_corporate ? (
                      <img src={corporate_selected} alt="" />
                    ) : (
                      <img src={corporate_icon} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={show_corporate ? "Corporate ▲" : "Corporate ▼"}
                    sx={{
                      opacity: open ? 1 : 0,
                      marginTop: "7px",
                      color: show_corporate
                        ? "#007cc3"
                        : !show_corporate && change_corporate
                        ? "white"
                        : "white",
                    }}
                    onMouseEnter={() => setChange_Corporate(true)}
                    onMouseLeave={() => setChange_Corporate(false)}
                  />
                </ListItemButton>
              </ListItem>

              {show_corporate ? (
                <ListItem
                  disablePadding
                  // sx={{ display: "block",backgroundColor:show?"#444a4f":"#89BBE0"}}
                  sx={{
                    display: "block",
                    backgroundColor: show_monthly ? "white" : "#007cc3",
                    marginLeft: "20px",
                    marginTop:"10px"
                  }}
                  // onClick={() => navigate("/Category_Leadsource_wise")}
                  onClick={() => setShow_Monthly(!show_monthly)}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <CalendarMonthIcon
                    sx={{ color: show_monthly ? "#007cc3" : "white", fontSize: "23px" }}
                  />
                     
                    </ListItemIcon>
                    <ListItemText
                      primary={show_monthly ? "Monthly Digest ▲" : "Monthly Digest ▼"}
                      sx={{
                        opacity: open ? 1 : 0,
                        marginTop: "7px",
                        color: show_monthly
                          ? "#007cc3"
                          : !show_monthly && change_monthly
                          ? "white"
                          : "white",
                      }}
                      onMouseEnter={() => setChange_Monthly(true)}
                      onMouseLeave={() => setChange_Monthly(false)}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                <></>
              )}

              {show_monthly ? (
                <ListItem
                  disablePadding
                  // sx={{ display: "block",backgroundColor:show?"#444a4f":"#89BBE0"}}
                  sx={{
                    display: "block",
                    backgroundColor: show_hub ? "white" : "#007cc3",
                    marginLeft: "20px",
                    marginTop:"10px"
                  }}
                  // onClick={() => navigate("/Category_Leadsource_wise")}
                  onClick={() => setShow_Hub(!show_hub)}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {/* <BiotechIcon
                    sx={{ color: show_hub ? "#007cc3" : "white", fontSize: "23px" }}
                  /> */}
                      {show_hub ? (
                        <img style={{height:"22px"}} src={hub_icon} alt="" />
                      ) : (
                        <img style={{height:"22px"}} src={hub_icon} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={show_hub ? "HUB ▲" : "HUB ▼"}
                      sx={{
                        opacity: open ? 1 : 0,
                        marginTop: "7px",
                        color: show_hub
                          ? "#007cc3"
                          : !show_hub && change_hub
                          ? "white"
                          : "white",
                      }}
                      onMouseEnter={() => setChange_Hub(true)}
                      onMouseLeave={() => setChange_Hub(false)}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                <></>
              )}




              {show_hub ? (
                <>
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}
                    // onClick={() => navigate("/Category_Leadsource_wise")}
                    onClick={(e) => handlePath(e, "/sales_leaderboard","corporate")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      {/* <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FileCopyIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        />
                      </ListItemIcon> */}

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        <p style={{ marginBottom: "0rem" }}>Sales Leaderboard</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
               
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}
                    // onClick={() => navigate("/Category_Leadsource_wise")}
                    onClick={(e) => handlePath(e, "/salesperson_wise_invoice","corporate")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      {/* <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FileCopyIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        />
                      </ListItemIcon> */}

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Salesperson wise
                        <p style={{ marginBottom: "0rem" }}>Invoice</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}
                    // onClick={() => navigate("/Category_Leadsource_wise")}
                    onClick={(e) => handlePath(e, "/sample_business_report","corporate")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      {/* <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FileCopyIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        />
                      </ListItemIcon> */}

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Sample Business
                        <p style={{ marginBottom: "0rem" }}>Report</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}
                    onClick={(e) => handlePath(e, "/purchase_order_report")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      {/* <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FileCopyIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        />
                      </ListItemIcon> */}

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Purchase Order
                        <p style={{ marginBottom: "0rem" }}>Report</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}
                    onClick={(e) =>
                      handlePath(e, "/billing_report_monthly_digest")
                    }
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      {/* <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FileCopyIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        />
                      </ListItemIcon> */}

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        <p style={{ marginBottom: "0rem" }}> Billing Report</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                
                </>
              ) : (
                <></>
              )}

              {show_monthly ? (
                <ListItem
                  disablePadding
                  sx={{
                    display: "block",
                    backgroundColor: show_atlas ? "white" : "#007cc3",
                    marginLeft: "20px",
                  }}
                  onClick={() => setShow_Atlas(!show_atlas)}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {show_atlas ? (
                        <img style={{height:"15px"}} src={atlas_icon} alt="" />
                      ) : (
                        <img style={{height:"15px"}} src={atlas_icon} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={show_atlas ? "Atlas ▲" : "Atlas ▼"}
                      sx={{
                        opacity: open ? 1 : 0,
                        marginTop: "7px",
                        color: show_atlas
                          ? "#007cc3"
                          : !show_atlas && change_atlas
                          ? "white"
                          : "white",
                      }}
                      onMouseEnter={() => setChange_Atlas(true)}
                      onMouseLeave={() => setChange_Atlas(false)}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                <></>
              )}
              {show_atlas ? (
                <>
                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}
                    onClick={(e) => handlePath(e, "/tags_level_report")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      {/* <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FileCopyIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        />
                      </ListItemIcon> */}

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Tags Level Report
                        {/* <p style={{ marginBottom: "0rem" }}></p> */}
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}
                    onClick={(e) => handlePath(e, "/leads_leader")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      {/* <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FileCopyIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        />
                      </ListItemIcon> */}

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        <p style={{ marginBottom: "0rem" }}>Leads Leader</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}
                    onClick={(e) => handlePath(e, "/overdue_leads_sales")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      {/* <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FileCopyIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        />
                      </ListItemIcon> */}

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Overdue Leads
                        <p style={{ marginBottom: "0rem" }}>Sales</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>


                
                </>
              ) : (
                <></>
              )}

{show_corporate ? (
                <ListItem
                  disablePadding
                  // sx={{ display: "block",backgroundColor:show?"#444a4f":"#89BBE0"}}
                  sx={{
                    display: "block",
                    backgroundColor: show_invoice ? "white" : "#007cc3",
                    marginLeft: "20px",
                    marginTop:"10px"
                  }}
                  // onClick={() => navigate("/Category_Leadsource_wise")}
                  onClick={() => setShow_Invoice(!show_invoice)}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
             <FileCopyIcon  sx={{ color: show_invoice?"#007cc3":"white", fontSize: "23px" }} />
                    </ListItemIcon>

                   {show_invoice ? <ListItemText
                              sx={{
                                opacity: open ? 1 : 0,
                                marginTop: "7px",
                                color: show_invoice
                                  ? "#007cc3"
                                  : !show_invoice && change_invoice
                                  ? "white"
                                  : "white",
                              }}
                              onMouseEnter={() => setChange_Invoice(true)}
                              onMouseLeave={() => setChange_Invoice(false)}
                      >
                        Invoice Creation 
                        <p style={{ marginBottom: "0rem" }}>Dashboard ▲</p>
                      </ListItemText>: <ListItemText
                              sx={{
                                opacity: open ? 1 : 0,
                                marginTop: "7px",
                                color: show_invoice
                                  ? "#007cc3"
                                  : !show_invoice && change_invoice
                                  ? "white"
                                  : "white",
                              }}
                              onMouseEnter={() => setChange_Invoice(true)}
                              onMouseLeave={() => setChange_Invoice(false)}
                      >
                        Invoice Creation 
                        <p style={{ marginBottom: "0rem" }}>Dashboard ▼</p>
                      </ListItemText>}
                  </ListItemButton>
                </ListItem>
              ) : (
                <></>
              )}

              { show_invoice? <> <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}
                    onClick={(e) => handlePath(e, "/invoice_daily","corporate")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      {/* <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FileCopyIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        />
                      </ListItemIcon> */}

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Invoice Daily
                        <p style={{ marginBottom: "0rem" }}>Report</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}
                    onClick={(e) => handlePath(e, "/invoice_monthly","corporate")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      {/* <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FileCopyIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        />
                      </ListItemIcon> */}

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Invoice Monthly
                        <p style={{ marginBottom: "0rem" }}>Report</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "40px" }}
                    onClick={(e) => handlePath(e, "/invoice_monthly_data","corporate")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      {/* <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <FileCopyIcon
                          sx={{ color: "white", fontSize: "23px" }}
                        />
                      </ListItemIcon> */}

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Invoice Monthly
                        <p style={{ marginBottom: "0rem" }}>Data</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem></>:<></>}

                  {show_corporate?<>  
               

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) => handlePath(e, "/invoice_corporate","corporate")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                     <img src={invoice_dashboard_img} alt="" />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                     
                        <p style={{ marginBottom: "0rem" }}> Invoice Dashboard</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>


                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) => handlePath(e, "/interested_lead_report","corporate")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                     <img src={leads} alt="" />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Interested Lead
                        <p style={{ marginBottom: "0rem" }}>Reports</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) => handlePath(e, "/corporate_client_oh_dashboard","corporate")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                     <img src={client_icon} alt="" />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Corporate Client
                        <p style={{ marginBottom: "0rem" }}>OH Dashboard</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>


                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) => handlePath(e, "/sample_type_report_bde","corporate")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                     <img src={sample_type} alt="" />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Sample Type
                        <p style={{ marginBottom: "0rem" }}>Report (BDE)</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) => handlePath(e, "/new_customer_corp","corporate")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                     <ContactEmergencyIcon sx={{color:"white"}} />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        New Customer
                        <p style={{ marginBottom: "0rem" }}>Details</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>


                  {/* <ListItem
                    disablePadding
                    sx={{ display: "block", marginLeft: "20px" }}
                    onClick={(e) => handlePath(e, "/corporate_oh_dashboard")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                     <img src={oh_icon} alt="" />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "white",
                          marginLeft: "-7px",
                        }}
                      >
                        Corporate OH
                        <p style={{ marginBottom: "0rem" }}>Dashboard</p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem> */}

               

                  </>:<></>}




            </>
          ) : (
            <></>
          )}

       
        </List>
      </Drawer>
    </Box>
  );
}
