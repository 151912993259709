import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/Minidrawer";
import MUIDataTable from "mui-datatables";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { DatePicker, Space } from "antd";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Select2 from "react-select";
import dayjs from "dayjs";
import moment from "moment";
import Swal from "sweetalert2";
import GroupIcon from '@mui/icons-material/Group';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import BarChartIcon from '@mui/icons-material/BarChart';
import MovingIcon from '@mui/icons-material/Moving';

import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const customStyles = {
  option: (provided) => ({
    ...provided,
    textAlign: "left",
    fontSize: "13px",
    color: "black",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "14px",
    textAlign: "left",
  }),
  valueContainer: (base) => ({
    ...base,
     fontSize:"13px",
     textAlign: "left"
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, // Set this to the desired z-index
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
};

function New_customer_corp() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id");
  let admin_param = storage.getItem("admin_param");
  let dept = storage.getItem("DEPT");

  const [customer_data, setCustomer_Data] = useState([]);
  const [customer_load, setCustomer_Load] = useState(false);

  const [params, setParams] = useState("MTD");
const [range_flag, setRange_Flag] = useState(false);
const [selectedFromDate, setSelectedFromDate] = useState(new Date());
const [selectedToDate, setSelectedToDate] = useState(new Date());
const [date_change_flag,setDate_change_Flag] = useState(false);

  const [salesperson_list,setSalesperson_List] = useState([]);
const [selected_salesperson,setSelected_salesperson] = useState([]);

const [count_data,setCount_Data] = useState([]);
const [count_load,setCount_Load] = useState(false)

  const get_new_customer_details = async () => {
    setCustomer_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/new_customers_details`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            dept_id:
              dept == "Tech" || dept == "Admin" || dept == "Management"
                ? admin_param
                : dept == "FnB Sales"
                ? "1"
                : dept == "Corporate Sales"
                ? "2"
                : "all",
                "get_date":params == "Custom Date"?"":params,
                "start_date":params == "Custom Date"?moment(selectedFromDate)?.format("YYYY-MM-DD"):"",
                "end_date":params == "Custom Date"?moment(selectedToDate)?.format("YYYY-MM-DD"):"",
                "salesperson":selected_salesperson?.value == undefined ?"all":selected_salesperson?.value
          }),
        }
      );
      let data = await res.json();
      setCustomer_Load(false);
      setCustomer_Data(data?.data);
    } catch (error) {
      setCustomer_Load(false);
      setCustomer_Data([]);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

  const get_new_customer_count = async () => {
    setCount_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/new_customers_order_count_details`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            dept_id:
              dept == "Tech" || dept == "Admin" || dept == "Management"
                ? admin_param
                : dept == "FnB Sales"
                ? "1"
                : dept == "Corporate Sales"
                ? "2"
                : "all",
                "get_date":params == "Custom Date"?"":params,
                "start_date":params == "Custom Date"?moment(selectedFromDate)?.format("YYYY-MM-DD"):"",
                "end_date":params == "Custom Date"?moment(selectedToDate)?.format("YYYY-MM-DD"):"",
                "salesperson":selected_salesperson?.value == undefined ?"all":selected_salesperson?.value
          }),
        }
      );
      let data = await res.json();
      setCount_Load(false);
      setCount_Data(data?.data);
    } catch (error) {
      setCount_Load(false);
      setCount_Data([]);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };



  const get_salesperson = async()=>{
    // setData_Load(true)
    try {
        let res = await fetch(`https://reportapi.equinoxlab.com/get_unique_salesperson`,{
            method:"POST",
            headers: {"Content-Type" : "Application/JSON"},
            body: JSON.stringify({
              dept_id:
              dept == "Tech" || dept == "Admin" || dept == "Management"
                ? admin_param
                : dept == "FnB Sales"
                ? "1"
                : dept == "Corporate Sales"
                ? "2"
                : "all",
            })
        })
        let data = await res.json();
        // setData_Load(false)
         if(data?.response?.code == "200"){
            let dropdown_data = [];
            for (let i = 0; i < data?.Data?.length; i++) {
              dropdown_data.push({
                label: data?.Data[i]?.salesperson,
                value: data?.Data[i]?.salesperson,
              });
            }
            setSalesperson_List(dropdown_data);
   
         }else{
            setSalesperson_List([])
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "error",
                title: data.response?.message,
              });
         }
    } catch (error) {
        // setData_Load(false)
        setSalesperson_List([])
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title: "Something Went Wrong",
          });
    }
}

  useEffect(() => {
    if (Role_id != "" && Role_id != null) {
      get_new_customer_details();
      get_new_customer_count();
    }
  }, [selectedFromDate,selectedToDate,selected_salesperson]);

  useEffect(()=>{
    if (Role_id != "" && Role_id != null) {
      get_salesperson();
    }
  },[])


  const handleDateRange = (e) => {
    if (e.target.value == "Custom Date") {
      setParams("Custom Date");
      setRange_Flag(true);
    } else {
      setRange_Flag(false);
      setParams(e.target.value);
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());

 
    }
  };


  const handleSelectDateRange = (new_val) => {
    if (new_val !== null) {
        setDate_change_Flag(true)
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);

    } else {
      setParams("MTD");
      setRange_Flag(false);
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      setDate_change_Flag(false)
  
    }
  };

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "client_name",
      label: `Client Name`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div id={styles.body_unit_contain}>
              <p>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "site_name",
      label: `Site Name`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div id={styles.body_unit_contain}>
              <p>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "salesperson",
      label: `Sales Person`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div id={styles.body_unit_contain}>
              <p>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "lead_source",
      label: `Lead Source`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div id={styles.body_unit_contain}>
              <p>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "lead_category",
      label: `Lead Category`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div id={styles.body_unit_contain}>
              <p>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "oh_no",
      label: `OH No.`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div id={styles.body_unit_contain}>
              <p>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "oh_value",
      label: `OH Value (₹)`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return (
            <div id={styles.body_unit_contain}>
              <p style={{textAlign:"center"}}>{new Intl.NumberFormat("en-IN").format(row)}</p>
            </div>
          );
        },
      },
    },
    {
      name: "business_type",
      label: `Business Type`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div id={styles.body_unit_contain}>
              <p>{row}</p>
            </div>
          );
        },
      },
    },
  ];
  return (
    <>
      <MiniDrawer />
      <div className="container-fluid pt-2" style={{ paddingLeft: "0.8vw" }}>
      {customer_load || count_load? (
            <img src={report_loader} id={styles.loader} />
          ) : (<>

<div className="row">
  {/* <div className="col-md-10"></div> */}
  <div className={range_flag?"col-6":"col-8"}></div>
      <div className="col-sm-6 col-md-2">
      <Select2
                // isMulti
                name="label"
                options={salesperson_list}
                className="basic-single"
                classNamePrefix="select"
                styles={customStyles}
                isClearable={true}
                value={selected_salesperson}
                onChange={(e)=>setSelected_salesperson(e)}
                closeMenuOnSelect={true}
                placeholder="Select Salesperson"
                maxMenuHeight="180px"
              />
      </div>
      <div className="col-sm-6 col-md-2">
      <Box
                          sx={{
                            width: "100%",
                            backgroundColor: "white",
                            height: "37px",
                            borderRadius: "5px",
                          }}
                        >
                          <FormControl size="small" fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              style={{
                                width: "100%",
                                height: "37px",
                                fontSize: "14px",
                                textAlign: "left",
                              }}
                              onChange={handleDateRange}
                              value={params}
                            >
                              <MenuItem value="" disabled>Please Select</MenuItem>
                              <MenuItem value="Today">Today</MenuItem>
                              <MenuItem value="MTD">MTD</MenuItem>
                              <MenuItem value="QTD">QTD</MenuItem>
                              <MenuItem value="YTD">YTD</MenuItem>
                              <MenuItem value="FYTD">FYTD</MenuItem>
                              <MenuItem value="Custom Date">Custom Date</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
      </div>
      {range_flag ? (
                           <div className="col-sm-6 col-md-2">
                            <DateRangePicker
                              // defaultValue={[new Date(), new Date()]}
                              format="dd MMM yyyy"
                              value={date_change_flag?[selectedFromDate, selectedToDate]:[]}
                              onChange={handleSelectDateRange}
                              size="md"
                              placement="bottomEnd"
                              placeholder="Select Date Range"
                              style={{ width: "100%" }}
                              preventOverflow
                            />
                          </div>
                        ) : (
                          <></>
                        )}
</div>

<div className="row mt-3" id={styles.tab_container}>
  <div className="col-2">
    <h4>{count_data[0]?.total_customers}</h4>
    <GroupIcon id={styles.tab_icon} />
    <p>Total New Customer</p>
  </div>
  <div className="col-2">
  <h4>{count_data[0]?.total_orders}</h4>
  <CurrencyRupeeIcon id={styles.tab_icon} />
    <p>Total Order Created</p>
  </div>
  <div className="col-2">
  <h4>{count_data[0]?.avg_order_value}</h4>
  <BarChartIcon id={styles.tab_icon} />
    <p>Avg Order Frequency</p>
  </div>
  <div className="col-2">
  <h4>{ typeof(count_data[0]?.total_order_value) == "number"? new Intl.NumberFormat("en-IN").format(count_data[0]?.total_order_value):0}</h4>
  <MovingIcon id={styles.tab_icon} />
   <p>Total Order Value</p>
  </div>
</div>
        <div  className="row mt-3 mb-5">
      
            <MUIDataTable
              title={
                admin_param == "1" ? (
                  <h5 style={{ textAlign: "left" }}>
                    New Customer Details ( FnB Sales )
                  </h5>
                ) : (
                  <h5 style={{ textAlign: "left" }}>
                    New Customer Details ( Corporate Sales )
                  </h5>
                )
              }
              data={customer_data}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                filter: false,
                print: false,
                responsive: "standard",
                download: false,
                fixedHeader: true,
                tableBodyMaxHeight: "40vh",
                rowsPerPageOptions: [10, 30, 50, 100],
                // rowsPerPage: "",

                setTableProps: () => {
                  return {
                    padding: 0,
                  };
                },
                textLabels: {
                  body: {
                    noMatch: "Data Not Available",
                  },
                },
         
              }}
            />
         
        </div>
        </> )}
      </div>
    </>
  );
}

export default New_customer_corp;
