import React from 'react'
import New_customer_corp from '../pages/New_customer_corp'

function New_customer_fnb() {
  return (
    <>
    <>
      <New_customer_corp/>
    </>
    </>
  )
}

export default New_customer_fnb
